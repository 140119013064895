import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateIcon } from "../../../assets/svg";

const DateRangeSelector = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedRange, setSelectedRange] = useState(props.dateRanges[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSelectRange = (range) => {
    props.setSelectedRange([range]);
    handleClose();
  };

  return (
    <div style={{ float: "right", marginRight: "1.5em",  display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignContent: "stretch",
      justifyContent: "space-evenly",
      alignItems: "flex-end" }}>
      <Box>
        <span
          style={{
            fontFamily: "Inter",
            fontSize: "9px",
            fontWeight: "400",
            lineHeight: "10.89px",
            textAlign: "left",
            marginRight: "8px",
          }}
        >
          PO Creation Date Range
        </span>

        <Button
          variant="outlined"
          onClick={handleClick}
          sx={{
            width: "190px",
            height: "28px",
            padding: "4px",
            gap: "3px",
            top: "10px",
            border: "1px solid #CCCCCC !important", // !important ensures overriding default styles
            borderRadius: "6px !important", // Adds important flag
            color: "#3D3D3B !important", // Ensure color persists
            marginBottom: "15px",
            opacity: 1,
            textTransform: "none",
            fontSize: "10px !important",
            position: "relative",
            "&:hover": {
              borderColor: "#BBBBBB !important", // Override hover styles
              backgroundColor:"#f2f5f4 !important"
            },
          }}
        >
          <span
            style={{
              position: "absolute",
              left: "8px",
              top: "59%",
              transform: "translateY(-50%)",
            }}
          >
            <DateIcon />
          </span>
          <span style={{position:"relative"}}>{props.selectedRange[0]}</span>
          <span
            style={{
             
              position: "absolute",
              right: "5px",
              top: "59%",
              transform: "translateY(-50%)",
            }}
          >
            <KeyboardArrowDownIcon style={{ height:"0.6em",
              width:"0.6em",}} />
          </span>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "date-range-selector",
            sx: {
              position: "relative",
              paddingTop: "2px",
              paddingBottom: "6px",
            },
          }}
          PaperProps={{
            style: {
              width:"192px",
              maxHeight: 153,
              overflowY: "auto",
              marginTop: "10px",
              borderRadius: "6px",
              border: "1px solid #DFE2DE",
              boxShadow: "none",
            },
          }}
        >
          {props?.dateRanges?.map((range, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSelectRange(range)}
              sx={{
                borderRadius: "4px",
                marginTop: "4px",
                paddingTop: "5px",
                paddingLeft: "7px",
                paddingRight: "1px",
                marginLeft: "4px",
                marginRight: "4px",
              }}
            >
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                {range}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </div>
  );
};

export default DateRangeSelector;
