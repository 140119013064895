import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, useRef } from "react";
import {Loader, NoResultFound} from '../../../assets/icons/FallBackComponents'
import LegendWithMenu from "../../ChartLegend/chartLegendWithMenu"
import ReactFC from "react-fusioncharts";
import TimeSpanComponentForFunnel from "../Funnel/TimeSpanComponentForFunnel";
import DateRangeSelector from "./POWeeklyDateRange";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Resolves charts dependancy
charts(FusionCharts);


ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const BarChartPO=(props)=>  {
    console.log("barChartPo - ",props);
    function GetDateFrom (){
        let date = sessionStorage.getItem("oldDate") || weeksOldDate 
        return new Date(date);
      }

    let weeksOldDate = new Date();
    weeksOldDate.setDate(weeksOldDate.getDate() - 7);
    const [selectedFrequency, setSelectedFrequency] = React.useState("Weekly")
  
    const [fromDate, setFromDate] = React.useState(GetDateFrom())

  const controllerRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [dataSource,setDataSource] = useState([]);
  const [AttributeList,setAttributeList] = useState({})
  const [selectedRange, setSelectedRange] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dividedLines, setDivededLines] = useState(2);
  const chart = {
      plottooltext: '<div style="border-radius:8px"><b>$seriesName</b> $dataValue</div>',
      showPercentValues: '1',
      theme: 'fusion',
      plotSpacePercent: '98',
      showHoverEffect:0,
      legendItemFontBold: 1,
      drawCustomLegendIcon: 1,
      placeValuesInside :0,
      legendIconStartAngle: 45,
  }
  
  let colors = [
    "#016B40",
    "#1ABF6E",
    "#92CC38",
    "#C2AE46",
    "#FDC731",
    "#FAA544",
    "#FC693E",
    "#FDA6A5",
    "#D83545",
    "#DE2268",
    "#FD57BA",
    "#F899EC",
    "#9247D3",
    "#6D41C6",
];

function getColor(index) {
    // Ensure index is within bounds
    index = index % colors.length;
    return colors[index];
}


const getBarChartData = () => {
  setLoading(true);

  if (controllerRef.current) {
    controllerRef.current.abort();
  }

  const controller = new AbortController();
  controllerRef.current = controller;

  const POANALYTICS_URL = `${window._env_.UNIFIED_URL}/POanalytics/populateBarChart?tenantId=${props.tenantId}&icp=${props.icpSelected}&weekOffset=3&startDate=${startDate}&endDate=${endDate}`;

  const requestBody = {
    cardDetails: props.cardDetails,
  };

  fetch(POANALYTICS_URL, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
    signal: controllerRef.current?.signal,
    credentials: "include",
  })
    .then((response) => {
      console.log("first then in PO response")
      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data for PO bar chart:", data);
      setLoading(false);
     

      if (data.success) {
        // Dynamically create chart data from the API response
        const chartData = [
          { label: "Potential Opportunity", value: data.data.NumberofPO },
          { id:"outreach-label", label: "SDR Outreach", value: data.data.NumberofPOreached  } ,
          { label: "Opportunity Count", value: data.data.Numberofopportunities },
        ];
        const attList = chartData.map((dataset, index) => {
          const color = getColor(index);
          
          dataset.color = color;
          return { label: dataset.label, color, value:dataset.value };
        });

        if(data.data.NumberofPO == 0 && data.data.NumberofPOreached == 0 && data.data.Numberofopportunities == 0){
          setDivededLines(3);
        }
        setAttributeList(attList);

        console.log("Processed datasets for bar chart:", chartData);

        const newChartData = {
          chart: chart,
          data: chartData,
        };

        setDataSource(newChartData);
      } else {
        console.error("API returned error:", data.message);
        setDataSource({});
      }
    })
    .catch((error) => {
      console.error("Error fetching POanalytics data:", error);
      setLoading(false);
      setDataSource({});
    });
};

function parseDateRange(dateRange) {
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sept: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const [start, end] = dateRange.split(" - ");
  function formatDate(dateStr) {
    const [day, month, year] = dateStr.split(" ");
    return `${year}-${months[month]}-${day.padStart(2, "0")}`;
  }
  const start_date = formatDate(start);
  const endDate = formatDate(end);
  console.log("startDate-endDate",startDate,endDate);
  return { start_date, endDate };
}

function generateDateRangesFromMondayToSunday(startDate) {
  const dateRange = [];

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to avoid time mismatches

  // Convert startDate to a Date object and align it to the first Monday after the startDate
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0); // Normalize to avoid time mismatches
  const startDayOfWeek = currentDate.getDay();
  const daysUntilMonday = (8 - startDayOfWeek) % 7; // Number of days to the next Monday
  currentDate.setDate(currentDate.getDate() + daysUntilMonday);

  const formatDate = (date) =>
    date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

  // Generate date ranges from startDate's Monday to the Sunday of each week
  while (currentDate <= today) {
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday of the same week

    // Ensure the end date doesn't exceed today
    const validEndOfWeek = endOfWeek <= today ? endOfWeek : today;

    dateRange.push(`${formatDate(startOfWeek)} - ${formatDate(validEndOfWeek)}`);

    // Move to the next Monday
    currentDate.setDate(currentDate.getDate() + 7);
  }

  console.log("Generated Date Ranges:", dateRange);

  // Update state
  const result = dateRange.reverse().slice(3);
  setSelectedRange(result);
  setDateRanges(result);
}

function getMondayBeforeWeeks(weeks) {
  const today = new Date();
  const dayOfWeek = today.getDay();
  
  const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const targetDate = new Date(today);
  targetDate.setDate(today.getDate() - daysToMonday - weeks * 7);

  const year = targetDate.getFullYear();
  const month = String(targetDate.getMonth() + 1).padStart(2, '0'); 
  const day = String(targetDate.getDate()).padStart(2, '0');
  setStartDate(`${year}-${month}-${day}`);
  return `${year}-${month}-${day}`;
}

useEffect(() => {
  const startDate = "2024-12-09";
  getMondayBeforeWeeks(3);
  generateDateRangesFromMondayToSunday(startDate);
},[])

useEffect(() => {
  if(selectedRange.length > 0){
    console.log("selectedRange here",selectedRange);
    const { start_date, end_date } = parseDateRange(selectedRange[0]);
    setStartDate(start_date);
    setEndDate(end_date);
  }
  
},[selectedRange])

useEffect(() => {
  console.log("Coming in the API call");
  if (props.icpSelected && props.tenantId && startDate !== '') {

    getBarChartData();
  }
}, [props.icpSelected, props.softRefresh, props.refresh, startDate]);


const chartConfigs = {
    type: "column2d", // The chart type
    width: '98%', // Width of the chart
    height: "220", // Height of the chart
    dataFormat: "json", // Data type
    
    dataSource: {
      // Chart Configuration
      chart: {
        plotSpacePercent: 60.5,
        yAxisValueFontSize: "7px",//values on the timeline
        yAxisNameFontColor : "#5E5454",
        yAxisNameFontSize:"10px",
        xAxisValueFontSize: "7px",
        xAxisNameFontColor : "#5E5454",
        xAxisNameFontSize:"10px",
        "baseFontSize": "10px",
        theme: "fusion",
        showHoverEffect:0,
        showValues: 1,
        divLineDashed: 0,
        showZeroPlaneValue: 1,
        yaxisName: "Number of Accounts",
        xaxisName: "Stages",
        drawCrossLine:0,
       numDivLines: dividedLines, // Set the number of div lines to 4
        yAxisInterval: 25,
        placeValuesInside :0,
        canvastoppadding:20
       
      },
      
      // Chart Data
      data: (dataSource?.data || []).map(item => ({
        ...item, 
        color: item.label !== 'Opportunity Count' ? "#FC693E"  : "#00A75E",
        label: item.label === "SDR Outreach" 
        ? "SDR Outreach <br><span style='font-family: Inter; font-size: 8px; font-weight: 400; line-height: 9.68px; text-align: center; text-decoration-skip-ink: none;'> (until 2 weeks) post PO conversion</span>"
        : item.label,
      })),
    }
  };
  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ position: "relative"}}>
                <DateRangeSelector  selectedRange = {selectedRange} setSelectedRange = {setSelectedRange} dateRanges={dateRanges}  />
          <div>
        <ReactFC {...chartConfigs} />
      </div>
        </div>
      )
      
      }
      
    </>
  );
  

}

export default BarChartPO