import "./specialCardComponent.css";
import React, { useState,useMemo } from "react";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import Link from "@material-ui/core/Link";
import { DefaultCardHeaderIcon } from "../../assets/svg";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import RefreshIcon from "@mui/icons-material/Refresh";
import { BambooBoxIcon, InformationIcon, ContactCardHeaderIcon, RefreshIcon, MoreVertIcon, OpportunityCardIcon } from "../../assets/svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import "../../assets/styles/toast.css";
// import { Typography } from "@material-ui/core";
import { UNIFIED_FRONTEND_URL } from "../Layout/Cards/Type/Constant";
// import PublicIcon from '@mui/icons-material/Public';
import { GlobalWithInfo, PublicIcon } from '../../assets/svg'
import Tooltip from '@mui/material/Tooltip';
import {formateDateNew} from '../../helpers/permissionForCards.helper'

const SpecialCardBasedComponent = (props) => {
  const maxWidthApply = {
    InfluencedTrend: {maxWidth:true, maxHeight: true},
    funnelchart: {maxWidth:true, maxHeight: true},
    ConversionTrend: {maxWidth:true},
    OpportunitiesTrend: {maxWidth:true, maxHeight: true},
    VelocityTrend: {maxWidth:true},
    netNewAccount:{maxWidth:true,view : NetNewViewAll },
    Engagement_vs_Intent_KeySegment: {title: KeySegmentTitletext, view: Number(props?.count) ? ViewAll : Dummy },
    Engagement_KeySegment: {title: KeySegmentTitletext, view: Number(props?.count) ? ViewAll : Dummy },
    Intent_KeySegment: {title: KeySegmentTitletext, view: Number(props?.count) ? ViewAll : Dummy },
  };
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: true,
  });
  console.log("SpecialCardBasedComponent", props);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const formatDate = useMemo(() => {
    return (dateString) => {
      if (!dateString || dateString.length === 0) {
        return '';
      }

      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
    
      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    };
  }, []);

  const ViewAllComponent =  maxWidthApply[props.cardDetails.uxComponent]?.view || Dummy
  const TitleComponent = maxWidthApply[props.cardDetails.uxComponent]?.title || BasicTitleText

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHide = async () => {
    const body = { hidden: true };
    const response = await axios.post(
      `${window._env_.CARD_SERVICE}/card/v2/hide/${props.tenantId}/${props.cardDetails.cardUuid}`,
      body,
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      const id = props.cardDetails.cardUuid;
      props.setRemovedCard(id);
      toast.success(<Alert severity="success">{"Insight hidden from the dashboard view successfully"}</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: <CloseIcon style={{ width:"18px"}}/>,
        newestOnTop: true,
      });
    } else {
      toast.error(<Alert severity="error">{"An error occurred for the selected action. Please try again."}</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSoftRefresh=()=>{
    props.setSoftRefresh((softRefresh)=>!softRefresh)
  }

  return (
    <div
      className={maxWidthApply[props.cardDetails.uxComponent]?.maxWidth && maxWidthApply[props.cardDetails.uxComponent]?.maxHeight ? "containerHeight" : maxWidthApply[props.cardDetails.uxComponent]?.maxWidth ? "containerWidth " : "container"}>
      <div className="description">
        <div className="headerLeft">
        {props?.cardDetails?.uxComponent === 'barchart2'? <OpportunityCardIcon/> : props?.cardDetails.cardObject?.toLowerCase() === 'account' ?  <DefaultCardHeaderIcon /> : <ContactCardHeaderIcon /> }
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TitleComponent {...props}></TitleComponent>
            </div>
        </div>
        <div className="headerRight">
         {(props.cardDetails.global_card === "true" || props.cardDetails.global_card === true) &&
         <Tooltip title="This card does not support filter and segments" arrow>
          <div className="public-icon">
            {(props.segmentName !== "") && (props?.requestBody?.length > 0 || props.segmentName !== 'All Accounts') ? <GlobalWithInfo /> : <PublicIcon />}
          </div>
         </Tooltip>}
         {props?.cardDetails?.uxComponent === 'barchart2' ? 
         <div>
          <Tooltip title="This is a global insight. Segment selection is not applicable for this view" arrow>
              <div>
                <GlobalWithInfo />
              </div>
            </Tooltip>
        </div>
         : <></>}
          <div className="special-refresh-more-div">
            <div className="icon-container" onClick={handleSoftRefresh}>
              <Tooltip title='Refresh' arrow>
                <div style={{ display: 'flex' }}>
                  <RefreshIcon className="refresh-icon" />
                </div>  
              </Tooltip>  
            </div>
            <div className="icon-container" onClick={handleOpenMenu}>
              <Tooltip title='More' arrow>
                <div style={{ display: 'flex' }}>
                  <MoreVertIcon className="more-icon" />
                </div>
              </Tooltip>
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem style={{ width: '135px', fontSize: '14px', marginLeft: '4px', marginRight: '4px', borderRadius: '4px', marginTop: '-4px', marginBottom: '-4px', paddingBottom: '8px' }} onClick={handleHide}>Hide</MenuItem>
          </Menu>
        </div>
      </div>
      <div className="card-body">{props.children}</div>
      <div className="footer">
        <div className="bambooboxIcon">
        <Tooltip title={
          <React.Fragment>
            <div style={{ lineHeight: '2' }}>
              {`Created by: ${props?.cardDetails?.createdBy}`} <br />
              {`Created at: ${formateDateNew(props?.cardDetails?.createdAt)}`}
            </div>
          </React.Fragment>
          } arrow >
            <div><BambooBoxIcon /></div>
          </Tooltip>
          <div className="datetext">Last Updated {formateDateNew(Date.now())}</div>
        </div>
          <ViewAllComponent
            segmentName={props.segmentName}
            icpSelected={props.icpSelected}
            requestBody={props.requestBody}
            specialFilter={props?.specialFilter}
            tabVal={props.tabVal}
            setOpenNewAccountDialog={props.setOpenNewAccountDialog}
            category={props?.category}
          ></ViewAllComponent>
      </div>
    </div>
  );
};
export default SpecialCardBasedComponent;

export function ViewAll(props) {
  return (
    <div className="viewAll">
      <Link style={{color: "#262626",textDecoration:'none'}}
        rel="noreferrer"
        href={`${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(
          encodeURIComponent(JSON.stringify(props?.specialFilter))
        )}/analytics_dashboard/${encodeURIComponent(
          encodeURIComponent(JSON.stringify(props.requestBody))
        )}?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${
          props.tabVal
        }&newDashboard=true&category=${props?.category}`}
      >
        <div>
          View All
        </div>
      </Link>
    </div>
  );
}

export function NetNewViewAll(props) {
    const openDialog=()=>{
        props.setOpenNewAccountDialog(true);
    }
    return (
      <div className="viewAll">
       
          <div onClick={openDialog}>
            View All
          </div>
      </div>
    );
  }

export function KeySegmentTitletext(props) {
  return (
    <Link
      style={{ color: "#262626", textDecoration: "none" }}
      rel="noreferrer"
      href={`${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(
        encodeURIComponent(JSON.stringify(props?.specialFilter))
      )}/analytics_dashboard/${encodeURIComponent(
        encodeURIComponent(JSON.stringify(props.requestBody))
      )}?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${
        props.tabVal
      }&newDashboard=true&category=${props?.category}`}
    >
      <span className="headertextdec" style={{ marginRight: '8px', fontSize: '14px', fontWeight: 600, color: '#3D3D3B' }}>
        {(props?.count ? props.count + " " : "") + props.cardDetails?.title}
      </span>
    </Link>
  );
}

export function BasicTitleText(props){
  return (
    <span className="headertextdec" style={{ marginRight: "8px", fontSize: "14px", fontWeight: 600, color: "#3D3D3B" }}>
      {props.cardDetails?.title}
      <Tooltip title={props?.cardDetails?.description || "Description not present"} arrow>
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <InformationIcon />
        </div>
      </Tooltip>
    </span>
  );
}

export function Dummy(props){
  return <></>
}