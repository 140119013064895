import React, { useState, useEffect } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import { Select, MenuItem, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useAuthState } from "@bamboobox/b2logincheck";
import Modal from "./modal";
import getTenantConfigJSON from "./getTenantConfigJson";
import { height, margin, minHeight, minWidth, width } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  rect5Text: {
    position: "absolute",
    width: "100px",
    height: "22px",
    left: "0px",
    top: "16%",
    bottom: "20%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#21DA8C",
  },
  root: {
    color: "#8A8A8A",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    color: "#686868 !important",
    fill: "#686868 !important",
    top: "0px",
    right: "0px",
    minHeight: "2.3rem",
    minWidth: "2.4rem",
    borderRadius: "99px",
  },
  selected: {
    borderRadius: "16px",
    background: "#21DA8C !important",
  },
  select: {
    "& .MuiSelect-select:focus": {
      background: 'transparent',
    },
    "& .MuiSvgIcon-root": {
      marginTop: "9px",
      marginRight: "12px",
      minWidth: "auto",
      minHeight: "auto",
      height: "12px",
      width: "12px"
    },
  },
}));

const relationArrConfig = {
  NUMERIC: [
    "is equal (=)",
    "greater than(>)",
    "less than(<)",
    "between",
    "is empty",
    "is not empty",
  ],
  BOOLEAN: ["true", "false"],
  DATE: [
    "is equal (=)",
    "before",
    "after",
    "between",
    "is empty",
    "is not empty",
    "is more than",
    "in the past",
  ],
  TEXT: [
    "includes",
    "contains",
    "starts with",
    "ends with",
    "is empty",
    "is not empty",
    "excludes",
    "does not contain",
  ],
  PICKLIST: ["includes", "excludes", "is empty", "is not empty"],
  DEFAULT: [
    "includes",
    "contains",
    "starts with",
    "ends with",
    "is empty",
    "is not empty",
    "excludes",
    "does not contain",
  ],
  pastValues: ["1 Day", "Week", "2 Weeks", "Month", "2 Months", "Quarter", "Year"],
};

export const FilterContext = React.createContext();
function Filters(props) {
  console.log("props in filters are:", props);

  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const [showModal, setShowModal] = useState(props.showModal || false);
  const [actionType, setActionType] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [accountFilterName, setAccountFilterName] = useState("");
  const [contactFilterName, setContactFilterName] = useState("");
  const [oppFilterName, setOppFilterName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState("");
  const {
    options,
    // customStyle,
    getFilterData,
    attributesValueURL,
    onClickFilter,
    onModalClose,
    typoLabel,
    chipToFocus,
    chipClicked,
    setChipClicked,
    icp,
    tenantConfigMapper={},
    contTenantConfigMapper = {},
    oppTenantConfigMapper = {},
    screen,
  } = props;
  console.log("props inside filters component", props);

  const [attributeList, setAttributeList] = useState([]);
  const [subfiltersConfig, setsubfiltersConfig] = useState({});

  let tenantConfigMapperMap = new Map(Object?.entries(tenantConfigMapper));
  const contTenantConfigMapperMap = new Map(
    Object?.entries(contTenantConfigMapper)
  );
  if(screen === "isOpportunityHub"){
    tenantConfigMapperMap = new Map(
      Object?.entries(oppTenantConfigMapper)
    );
  }
  useEffect(() => {
    let tempArray = [];
    if (typoLabel === "Contact Hub") {
      if (contactFilterName === "" && props?.attributeData.filterName === "") {
        tempArray = props?.attributeData.filter(
          (fil) => fil.accountHubFlag !== true
        );
        tempArray = [...tempArray, ...props?.accToContPerst];
      } else {
        tempArray = props?.attributeData;
      }
    } else if (typoLabel === "Opportunity Hub") {
      if (oppFilterName === "" && props?.attributeData.filterName === "") {
        tempArray = props?.attributeData.filter(
          (fil) => fil.accountHubFlag !== true
        );
        tempArray = [...tempArray, ...props?.accToOppPerst];
      } else {
        tempArray = props?.attributeData;
      }
    } else {
      tempArray = props?.attributeData;
    }
    setAttributeList(tempArray);
  }, [props.attributeData]);

  useEffect(() => {
    if (props.showModal === false) {
      handleClose();
      setFilterName("");
    }
    setShowModal(props.showModal);
  }, [props.showModal]);

  useEffect(() => {
    typoLabel === "Account Hub"
      ? setSelectedFilter(accountFilterName)
      : setSelectedFilter("");
  }, [typoLabel]);

  useEffect(() => {
    console.log("chip array is", props.accChipArray);
    console.log("chip array is", props.contChipArray);
    if (props?.accChipArray?.length === 0 && typoLabel === "Account Hub") {
      setAccountFilterName("");
      setSelectedFilter("");
    } else if (
      props?.contChipArray?.length === 0 &&
      typoLabel === "Contact Hub"
    ) {
      setContactFilterName("");
      setSelectedFilter("");
    } else if (
      props?.oppChipArray?.length === 0 &&
      typoLabel === "Opportunity Hub"
    ) {
      setOppFilterName("");
      setSelectedFilter("");
    }
  }, [props.accChipArray, props.contChipArray, props.oppChipArray]);

  const { Provider } = FilterContext;

  /* getting sub-filterConfig */
  const getSubfiltersConfig = async () => {
    if (Object.keys(subfiltersConfig).length === 0) {
      const configResp = await getTenantConfigJSON(tenantId, "filters", [
        "subfiltersConfig",
      ]);

      if (configResp?.length > 0) {
        setsubfiltersConfig(configResp[0]?.configVal);
      }
      // setsubfiltersConfig(subfilterConfig);
      return;
    }
  };
  getSubfiltersConfig();

  function onFilterChange(data, isSubfilter = false) {
    const { attrData, elementIndex } = data;
    const attrList = [...attributeList];

    if (isSubfilter) {
      const indexes = elementIndex.split("-");
      const parentFilterIndex = +indexes[0];
      const subFilterIndex = +indexes[1];
      attrList[parentFilterIndex]["subfilter"][subFilterIndex] = attrData;
    } else {
      attrList[elementIndex] = attrData;
    }

    setAttributeList(attrList);
  }

  function onAddFilterItem(attributeData, elementIndex, isSubfilter = false) {
    const attrList = [...attributeList];
    attrList[elementIndex] = attributeData;
    setShowErrors(false);
    if (isSubfilter) {
      if (attrList[elementIndex]?.subfilter?.length > 0) {
        attrList[elementIndex].subfilter = [
          ...attrList[elementIndex].subfilter,
          {
            attrId: nanoid(),
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      } else {
        attrList[elementIndex].subfilter = [
          {
            attrId: nanoid(),
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      }
      setAttributeList(attrList);
    } else {
      setAttributeList([
        ...attrList,
        {
          attrId: nanoid(),
          values: [],
          attribute: "",
          type: "",
          relation: "",
          isValid: false,
        },
      ]);
    }
  }

  function onOrFilterItem(attributeData, elementIndex, isSubfilter = false) {
    const attrList = [...attributeList];
    attrList[elementIndex] = attributeData;
    setShowErrors(false);
    if (isSubfilter) {
      if (attrList[elementIndex]?.subfilter?.length > 0) {
        attrList[elementIndex].subfilter = [
          ...attrList[elementIndex].subfilter,
          {
            attrId: nanoid(),
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      } else {
        attrList[elementIndex].subfilter = [
          {
            attrId: nanoid(),
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      }
      setAttributeList(attrList);
    } else {
      setAttributeList([
        ...attrList,
        {
          attrId: nanoid(),
          values: [],
          attribute: "",
          type: "",
          relation: "",
          isValid: false,
        },
      ]);
    }
  }

  function deleteFilter(elementIndex) {
    const attrList = [...attributeList];

    if (elementIndex.toString().includes("-")) {
      const indexes = elementIndex.split("-");
      const parentFilterIndex = +indexes[0];
      const subFilterIndex = +indexes[1];

      attrList[parentFilterIndex].subfilter.splice(subFilterIndex, 1);
    } else {
      attrList.splice(elementIndex, 1);
    }

    setAttributeList(attrList);
  }

  function onClearAttributes(elementIndex, clearIcon) {
    const attrList = [...attributeList];

    if (elementIndex.toString().includes("-")) {
      const indexes = elementIndex.split("-");
      const parentFilterIndex = +indexes[0];
      const subFilterIndex = +indexes[1];

      if (clearIcon) {
        attrList[parentFilterIndex].subfilter[subFilterIndex] = {
          label: "",
          relation: "",
          type: "",
          attribute: "",
          isValid: false,
          filterType: "",
          subfilter: [],
          values: [],
        };
      }else{
        attrList[parentFilterIndex].subfilter.splice(subFilterIndex, 1);
      }
    } else {
      attrList[elementIndex] = {
        ...attrList[elementIndex],
        label: "",
        relation: "",
        type: "",
        attribute: "",
        isValid: false,
        filterType: "",
        subfilter: [],
        values: [],
      };
    }

    setAttributeList(attrList);
  }

  function applyFilters(flag) {
    setChipClicked(false);
    const attrList = [...attributeList];
    console.log("after spliceattrlist", attrList);
    // attrList.splice(0, 1);
    console.log("before spliceattrlist", attrList);
    let errors = _.filter(attrList, { isValid: false });
    if (!attrList?.length) {
      errors[0] = [
        { relation: "", values: [], type: "", attribute: "", isValid: false },
      ];
    }
    console.log("errors in apply filters", errors);
    setShowErrors(errors?.length);
    getFilterData({
      attrList,
      actionType: flag,
      isValid: !errors.length,
      filterName,
    });
    setSelectedFilter(filterName);

    if (!errors?.length) {
      // if(flag !== 'SAVE_APPLY'&& flag !== 'ONLY_APPLY') {
      //     onModalClose();
      // }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();

  return (
    <Provider
      value={{
        onFilterChange,
        attributeList,
        onAddFilterItem,
        onOrFilterItem,
        deleteFilter,
        onClearAttributes,
        showErrors,
        attributesValueURL,
        actionType,
        applyFilters,
        filterName,
        typoLabel,
        chipToFocus,
        chipClicked,
        setChipClicked,
        icp,
        tenantConfigMapper: tenantConfigMapperMap,
        contTenantConfigMapper: contTenantConfigMapperMap,
        relationArrConfig,
        subfiltersConfig,
        screen: screen,
      }}
    >
      <FormControl size="small" variant="outlined">
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          labelId="filter-label"
          id="filter-setup"
          defaultValue="None"
          value={selectedFilter}
          style={{ borderRadius: "8px", width: '100px', height: "30px", backgroundColor: '#FFFFFF' }}
          startAdornment={
            <div style={{ marginRight: "2px", marginTop: "3px" }}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z" fill="#3D3D3B"/>
              </svg>
            </div>
          }
          displayEmpty={true}
          className={classes.select}
          renderValue={(value) => {
            if (value.length === 0)
              return (
                <div
                  style={{
                    alignItems: "center",
                    marginLeft: "2px",
                    color: "#3D3D3B",
                    fontSize: '12px',
                    fontWeight: 500
                  }}
                >
                  Filters
                </div>
              );
            return (
              <div style={{ fontSize: '12px', marginLeft: "2px", paddingBottom: "3px" }}>
                {value}
              </div>
            );
          }}
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}
          // renderValue={(value) => <label>{value}</label>}
          // label="Filter"
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: "16px",
              },
            },
            getContentAnchorEl: () => null,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            transformOrigin: { vertical: "top", horizontal: "right" },
          }}
        >
          <MenuItem
            margin="dense"
            value="New Filter"
            style={{
              display: "flex",
              alignItems: "center",
              width: "inherit",
              height: "36px",
              justifyContent: "center",
              background: "#fff",
              padding: "0 8px",
              color: "rgba(0, 0, 0, 0.87)",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontSize: "16px",
            }}
            onClick={(event) => {
              let tempArr = [];
              if (typoLabel === "Account Hub") {
                tempArr = [...props.body];
              } else if (typoLabel === "Contact Hub") {
                tempArr = [...props.contactBody];
              } else {
                tempArr = [...props.oppBody];
              }
              console.log("atl1", attributeList.length);
              if (tempArr.filter((e) => e.attrId).length > 0) {
                setAttributeList([...tempArr]);
              } else {
                setAttributeList([
                  ...tempArr,
                  {
                    attrId: nanoid(),
                    values: [],
                    attribute: "",
                    relation: "",
                    isValid: false,
                    index: 0,
                  },
                ]);
              }

              // setShowModal(true);
              onClickFilter("", "add");
              setSelectedFilter("Add Filter");
              setActionType("ADD");
              event.stopPropagation();
            }}
          >
            <AddIcon style={{ color: "rgba(0, 0, 0, 0.87)" }} /> New Filter
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem style={{ padding: 0, width: "inherit" }} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "36px",
                  backgroundColor: "#fff",
                  padding: "0 8px",
                  color: "rgba(0, 0, 0, 0.87)",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  fontSize: "16px",
                }}
                onClick={() => {
                  setSelectedFilter(option?.name || option?.filterName);
                  typoLabel === "Account Hub"
                    ? setAccountFilterName(option?.filterName)
                    : typoLabel === "Contact Hub"
                    ? setContactFilterName(option?.filterName)
                    : setOppFilterName(option?.filterName);
                  onClickFilter(option?.name || option?.filterName, "item");
                }}
              >
                <span
                  style={{
                    margin: 0,
                    // marginLeft: 15,
                    marginLeft: "5px",
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {option?.name || option?.filterName}
                </span>
                <div>
                  <IconButton
                    aria-label="edit"
                    style={{ padding: "0", marginRight: "5px" }}
                  >
                    <EditIcon
                      onClick={(event) => {
                        onClickFilter(
                          option?.name || option?.filterName,
                          "edit"
                        );
                        // setShowModal(true);
                        console.log("option", option);
                        setFilterName(option?.name || option?.filterName);
                        setActionType("EDIT");
                        event.stopPropagation();
                      }}
                      style={{ color: "#939393" }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    style={{ padding: "0", marginRight: "5px" }}
                  >
                    <DeleteIcon
                      onClick={(event) => {
                        // onClickFilter(option?.name,'delete');
                        if (
                          selectedFilter === option?.name ||
                          selectedFilter === option?.filterName
                        ) {
                          setSelectedFilter("");
                        }
                        setOpenConfirmation(true);
                        setDeletedFilter(option?.name || option?.filterName);
                        event.stopPropagation();
                      }}
                      style={{ color: "#939393" }}
                    />
                  </IconButton>
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {showModal && (
        <Modal
          showModal={showModal}
          onModalClose={(e) => {
            onModalClose();
            setShowModal(false);
            setFilterName("");
            handleClose();
            setActionType("");
          }}
          setActionType={setActionType}
          action={actionType}
          changeFilterName={setFilterName}
          filterName={filterName}
          showErrors={showErrors}
          typoLabel={typoLabel}
        />
      )}
      <Dialog
        open={openConfirmation}
        onClose={openConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to delete?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                onClickFilter(deletedFilter, "delete");
                handleClose();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Provider>
  );
}

export default Filters;
