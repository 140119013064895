/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@mui/material/Chip";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import DeleteIcon from "@mui/icons-material/Delete";
import DateRangePickerComponent from "./DateRangePickerComponent";
import SingleDatePickerComponent from "./SingleDatePickerComponent";
import { FilterContext } from "./../index";

function ValueComponent(props) {
  try {
    console.log("props inside ValueComponent", props);
    const {
      attributeData,
      currentAttribute,
      onAttributeChange,
      // shouldShowErrors,
      fetchAttributeValues,
      // elementIndex,
      attributeValues,
      selectAllValues,
      getOPtionFilt,
      inputText,
      setInputText,
    } = props;
    const { relationArrConfig } = useContext(FilterContext);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    function onNumberAttributeChange(val, ind) {
      let numValues = [...attributeData["values"]];
      numValues[ind] = val;
      onAttributeChange(numValues, "values");
    }

    const filters = createFilterOptions();

    const handleInput = (value) => {
      try {
        console.log("vallue", value);
        if (value) setInputText(value?.target?.value || "");
        else setInputText("");
      } catch (error) {
        setInputText("");
      }
    };

    return (
      <>
        {attributeData?.relation === "between" ? (
          <>
            {attributeData?.filterType === "NUMERIC" && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <input
                  margin="dense"
                  className="input"
                  style={{
                    borderRadius: "5px",
                    paddingLeft: 10,
                    width: "50%",
                    height: "36px",
                    marginTop: "9px",
                    border: "1px solid #ccc",
                    borderColor: "rgba(3, 3, 3, 0.2)",
                  }}
                  type="number"
                  value={attributeData?.values[0] || ""}
                  onChange={(event) => {
                    onNumberAttributeChange(event?.target?.value, 0);
                  }}
                  placeholder={currentAttribute?.placeholder}
                />
                <span
                  style={{
                    width: "20px",
                    paddingTop: "18px",
                    textAlign: "center",
                  }}
                >
                  {"-"}
                </span>
                <input
                  margin="dense"
                  className="input"
                  style={{
                    borderRadius: "5px",
                    paddingLeft: 10,
                    width: "50%",
                    height: "36px",
                    marginTop: "9px",
                    border: "1px solid #ccc",
                    borderColor: "rgba(3, 3, 3, 0.2)",
                  }}
                  type="number"
                  value={attributeData?.values[1] || ""}
                  onChange={(event) => {
                    onNumberAttributeChange(event?.target?.value, 1);
                  }}
                  placeholder={currentAttribute?.placeholder}
                />
              </div>
            )}
            {(attributeData?.filterType === "DATE" || currentAttribute?.filterType === "DATE") && (
              <DateRangePickerComponent
                toDate={toDate}
                setToDate={setToDate}
                fromDate={fromDate}
                setFromDate={setFromDate}
                style={{ width: "100%" }}
                attributeData={attributeData}
                onAttributeChange={onAttributeChange}
              />
            )}
          </>
        ) : (
          <>
            {attributeData?.relation === "includes" ||
            attributeData?.relation === "excludes" ? (
              <Autocomplete
                disableClearable
                disableCloseOnSelect
                color="primary"
                popupIcon={
                  <KeyboardArrowDownRoundedIcon
                    style={{ color: "rgba(3, 3, 3, 0.6)", position:"relative",top:"5px" }}
                  />
                }
                onChange={(event, newValue) => {
                  onAttributeChange(newValue, "values");
                }}
                value={attributeData?.values}
                inputValue={inputText}
                onOpen={() => {
                  fetchAttributeValues();
                }}
                id="controllable-states-demo1"
                options={attributeValues || attributeData?.values}
                getOptionLabel={(option) => getOPtionFilt(option)}
                getOptionSelected={(option, value) =>
                  attributeData.attribute === "Event Name" ||
                  attributeData.label === "Event Name"
                    ? option.eventName === value.eventName
                    : attributeData.attribute === "productName" ||
                      attributeData.label === "Product Name"
                    ? option.productName === value.productName
                    : attributeData.attribute === "programName" ||
                      attributeData.label === "Campaign Name"
                    ? option.programName === value.programName &&
                      option.source === value.source
                    : attributeData.attribute === "accounts|activityType" ||
                      attributeData.label === "Activity Type"
                    ? option.activityType === value.activityType &&
                      option.source === value.source
                    : attributeData.attribute === "accounts|labelName" ||
                      attributeData.attribute === "contacts|labelName" ||
                      attributeData.label === "Account Labels" ||
                      attributeData.label === "Labels"
                    ? option.labelName === value.labelName
                    : option === value
                }
                style={{ width: "100%", marginTop: "1px" }}
                filterOptions={(options, params) => {
                  const filtered = filters(options, params);
                  return ["Select All", ...filtered];
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        key={index}
                        className="input-chip-parent"
                        label={
                          <div className="chip-label">
                            {option.source && (
                              <img
                                alt="intent-logo"
                                src={`${window._env_.B2_INTENT_SETTING}/logos/${
                                  option.source == null
                                    ? ``
                                    : option.source.toLowerCase()
                                }.png`}
                              />
                            )}
                            <div
                              style={{
                                // maxWidth: "220px",
                                // width: "240px",
                                paddingLeft: option.source ? "" : "10px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: "13px",
                                fontStyle: "normal",
                                fontWeight: "400",
                              }}
                            >
                              {attributeData.attribute === "Event Name" ||
                              attributeData.label === "Event Name"
                                ? option.eventName
                                : attributeData.attribute === "productName" ||
                                  attributeData.label === "Product Name"
                                ? option.productName
                                : attributeData.attribute === "programName" ||
                                  attributeData.label === "Campaign Name"
                                ? option.programName
                                : attributeData.attribute ===
                                    "accounts|labelName" ||
                                  attributeData.attribute ===
                                    "contacts|labelName" ||
                                  attributeData.label === "Account Labels" ||
                                  attributeData.label === "Labels"
                                ? option.labelName
                                : option.activityType || option}
                            </div>
                          </div>
                        }
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderOption={(option, { selected }) => {
                  const selectAllProps =
                    option === "Select All" ? { checked: selectAllValues } : {};
                  return (
                    <div className="dropdownelements">
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ color: "rgba(3, 3, 3, 0.6)" }}
                            fontSize="small"
                          />
                        }
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                        {...selectAllProps}
                      />
                      <div className="imgblock">
                        <div style={{ marginRight: "8px", paddingTop: "6px" }}>
                          {option === "Select All" ? (
                            <div style={{ marginTop: "-4px" }}> {option} </div>
                          ) : attributeData.label === "Event Name" ||
                            attributeData.attribute === "productName" ? (
                            <></>
                          ) : (
                            <>
                              {option.source && (
                                <img
                                  alt="intent-logo"
                                  width="20"
                                  height="20"
                                  src={`${window._env_.B2_INTENT_SETTING}/logos/${
                                    option.source == null
                                      ? ``
                                      : option.source.toLowerCase()
                                  }.png`}
                                />
                              )}
                            </>
                          )}
                        </div>
                        <div
                          style={{ marginLeft: option.source ? "-10px" : "-16px" }}
                        >
                          {option === "Select All"
                            ? ""
                            : attributeData.attribute === "Event Name" ||
                              attributeData.label === "Event Name"
                            ? option.eventName
                            : attributeData.attribute === "productName" ||
                              attributeData.label === "Product Name"
                            ? option.productName
                            : attributeData.attribute === "programName" ||
                              attributeData.label === "Campaign Name"
                            ? option.programName
                            : attributeData.attribute ===
                                "accounts|labelName" ||
                              attributeData.attribute ===
                                "contacts|labelName" ||
                              attributeData.label === "Account Labels" ||
                              attributeData.label === "Labels"
                            ? option.labelName
                            : option.activityType || option}
                        </div>
                        <div>
                          {/* {option === "Select All" ? (
                            <></>
                          ) : attributeData.attribute === "Campaign Name" &&
                            (option.active === true ||
                              option.active === "true") ? (
                            <Elipse />
                          ) : (
                            <ElipseInactive />
                          )} */}
                        </div>
                      </div>
                    </div>
                  );
                }}
                multiple
                limitTags={1}
                sx={{
                  "&.MuiAutocomplete-tag": {
                    width: "70px !important",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    error={false}
                    {...params}
                    variant="outlined"
                    style={{
                      fontSize: "10px",
                      fontStyle: "italic",
                      // minWidth: "242px",
                    }}
                    onInput={handleInput}
                  />
                )}
              />
            ) : (
              <>
                {currentAttribute?.filterType === "DATE" ? (
                  <>
                    {attributeData?.relation === "in the past" ||
                    attributeData?.relation === "is more than" ? (
                      <FormControl
                        size="small"
                        variant="outlined"
                        style={{
                          // marginTop: "9px",
                          width: "100%",
                        }}
                      >
                        <Select
                          size="small"
                          options={relationArrConfig?.pastValues}
                          displayEmpty
                          value={attributeData?.values[0] || "value"}
                          style={{ width: "100%" }}
                          IconComponent={KeyboardArrowDownRoundedIcon}
                          inputProps={{
                            classes: {
                              icon: "relation-icon",
                              root: "root",
                            },
                          }}
                          renderValue={(value) =>
                            value === "value" ? (
                              <label
                                style={{
                                  color: "#A0A0A0",
                                  fontStyle: "italic",
                                }}
                              >
                                {value}
                              </label>
                            ) : (
                              <label>{value}</label>
                            )
                          }
                        >
                          {relationArrConfig?.pastValues?.map((data) => (
                            <MenuItem
                              value={data}
                              onClick={() => {
                                onAttributeChange([data], "values");
                              }}
                              style={{
                                textOverflow: "ellipsis",
                                borderRadius: "4px",
                              }}
                            >
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <SingleDatePickerComponent
                        style={{ width: "242px", maxWidth: "242px" }}
                        attributeData={attributeData}
                        onAttributeChange={onAttributeChange}
                      />
                    )}
                  </>
                ) : (
                  <input
                    margin="dense"
                    className="input"
                    style={{
                      borderRadius: "5px",
                      paddingLeft: 10,
                      marginTop: "1px",
                      width: "100%",
                      height: "36px",
                      border: "1px solid ",
                      borderColor: "rgba(3, 3, 3, 0.2)",
                    }}
                    type={
                      attributeData?.filterType === "NUMERIC"
                        ? "number"
                        : attributeData?.filterType === "DATE"
                        ? "date"
                        : "text"
                    }
                    value={attributeData?.values?.[0] || ""}
                    onChange={(event) => {
                      onAttributeChange(
                        event?.target?.value ? [event?.target?.value] : [],
                        "values"
                      );
                    }}
                    placeholder={currentAttribute?.placeholder ?? "Value"}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  } catch (e) {
    console.error("Error occurred while rendering value component.", e);
    return {
      /* <input
        margin="dense"
        className="input"
        style={{
          borderRadius: "5px",
          paddingLeft: 10,
          width: "100%",
          height: "40px",
          marginTop: "9px",
          border: "1px solid ",
          borderColor: "rgba(3, 3, 3, 0.2)",
        }}
        type={props?.currentAttribute?.inputType}
        value={props?.attributeData?.values?.[0] || ""}
        onChange={(event) => {
          onAttributeChange(
            event?.target?.value ? [event?.target?.value] : [],
            "values"
          );
        }}
        placeholder={props?.currentAttribute?.placeholder ?? "Value"}
      /> */
    };
  }
}

export default ValueComponent;
