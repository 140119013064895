import React from "react";
import "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./TimeSpanComponentForFunnel.css";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useEffect } from "react";

import { useLocation } from "react-router-dom";

function convertDateRangeToFormattedString(date) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dateString;
  dateString= `${date.getDate()} ${monthArray[date.getMonth()]
      }, ${date.getFullYear().toString().substring(2, 4)}`; 

  console.log(
    "convertDateRangeToFormattedString",
    date,
    dateString
  );
  return dateString;
}

function TimeSpanComponentForFunnel(props) {
  var threeWeeksOldDate = new Date();
  if(props.input=='1') threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 7) 
  else threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

  let frequencyValues = ["1 Day", "Weeks", "2 Weeks", "Months", "2 Months", "Quaters", "Years"];
  
  function GetDateFrom (){
    let date = sessionStorage.getItem("oldDate") || threeWeeksOldDate 
    return new Date(date);
  }
  const [open, setOpen] = React.useState(false);

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [oldDate, setOldDate] = React.useState(GetDateFrom());
  const [displayedDate, setDisplayedDate] = React.useState(
    sessionStorage.getItem("displayedDate")||convertDateRangeToFormattedString(oldDate)
  );

  const [fromDateMax, setFromDateMax] = React.useState(new Date());

  const [radioBtnValue, setRadioBtnValue] = React.useState(sessionStorage.getItem("radioBtnValue") || "fixed");

  const [numberFieldvalue, setNumberFieldvalue] = React.useState(sessionStorage.getItem("numberFieldvalue") || (props.input||"3"));
  const [intervalValue, setIntervalValue] = React.useState(sessionStorage.getItem("intervalValue")||"Weeks");
  const [prevOldDate, setPrevOldDate] = React.useState(oldDate)
  const [prevCurrentDate, setPrevCurrentDate] = React.useState(currentDate)
  const [prevIntervalValue, setPrevIntervalValue] = React.useState( intervalValue)
  const [prevNumberFieldValue, setPrevNumberFieldValue] = React.useState(numberFieldvalue);

  let setFromDate = props?.setFromDate;

    useEffect(() => {
      sessionStorage.setItem("displayedDate",displayedDate);
      sessionStorage.setItem("intervalValue", intervalValue);
      sessionStorage.setItem("numberFieldvalue", numberFieldvalue);
      sessionStorage.setItem("radioBtnValue", radioBtnValue);
      sessionStorage.setItem("oldDate", oldDate);
    }, [displayedDate,intervalValue,numberFieldvalue,radioBtnValue,oldDate])
    
  const handleNumberChange = (event) => {
    let todayDate = new Date();
    setCurrentDate(new Date());

    if (event?.target?.value < 1) event.target.value = 1;

    if (intervalValue === "Days") {
      todayDate.setDate(todayDate.getDate() - parseInt(numberFieldvalue));
    }
    if (intervalValue === "Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 1) * 7)
      );
    }
    if (intervalValue === "Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt(event.target.value || 1)
      );
    }
    if (intervalValue === "2 Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 1) * 14)
      );
    }
    if (intervalValue === "1 Day") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 1) * 1)
      );
    }
    if (intervalValue === "2 Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((event.target.value || 1) * 2)
      );
    }
    if (intervalValue === "Quarters") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((event.target.value || 1) * 3)
      );
    }
    if (intervalValue === "Years") {
      todayDate.setFullYear(
        todayDate.getFullYear() - parseInt(event.target.value || 1)
      );
    }

    console.log(
      "setting number to ",
      event?.target?.value,
      parseInt(event.target.value),
      intervalValue,
      todayDate
    );
    setOldDate(new Date(todayDate));
    sessionStorage.getItem("oldDate",new Date(todayDate))

    if (numberFieldvalue !== event?.target?.value) {
      setPrevNumberFieldValue(numberFieldvalue)
      setNumberFieldvalue(event.target.value);
    }
  };

  const handleIntervalChange = (event) => {
    // console.log("changed interval to ", event?.target?.value)

    // if (event?.target?.value === "Days" && numberFieldvalue > 366) { console.log("Days selected setting number to 366");setNumberFieldvalue("366");}
    // if (event?.target?.value === "Weeks" && numberFieldvalue > 53) {console.log("Weeks selected setting number to 53");setNumberFieldvalue("53");}
    // if (event?.target?.value === "Months" && numberFieldvalue > 12) {console.log("Months selected setting number to 12");setNumberFieldvalue("12");}
    // if (event?.target?.value === "Quarters" && numberFieldvalue > 4){ console.log("Quarters selected setting number to 4");setNumberFieldvalue("4");}
    // if (event?.target?.value === "Years" && numberFieldvalue > 1) {console.log("Years selected setting number to 1");setNumberFieldvalue("1");}

    let todayDate = new Date();
    setCurrentDate(new Date());

    if (event.target?.value === "Days") {
      todayDate.setDate(todayDate.getDate() - parseInt(numberFieldvalue));
    }
    if (event.target?.value === "Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((numberFieldvalue || 1) * 7)
      );
    }
    if (event.target?.value === "Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt(numberFieldvalue || 1)
      );
    }
    if (intervalValue === "2 Weeks") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 1) * 14)
      );
    }
    if (intervalValue === "1 Day") {
      todayDate.setDate(
        todayDate.getDate() - parseInt((event.target.value || 1) * 1)
      );
    }
    if (intervalValue === "2 Months") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((event.target.value || 1) * 2)
      );
    }
    if (event.target?.value === "Quarters") {
      todayDate.setMonth(
        todayDate.getMonth() - parseInt((numberFieldvalue || 1) * 3)
      );
    }
    if (event.target?.value === "Years") {
      let tem = todayDate;
      console.log(
        "handleIntervalChange - In years - before",
        tem,
        tem.getFullYear() - parseInt(event.target.value || 1)
      );
      todayDate.setFullYear(
        todayDate.getFullYear() - parseInt(numberFieldvalue || 0)
      );
      console.log(
        "handleIntervalChange - In years - after",
        todayDate,
        new Date(todayDate)
      );
    }

    setOldDate(new Date(todayDate));
    sessionStorage.getItem("oldDate",new Date(todayDate))

    console.log("setting interval to ", event?.target?.value);

    if (intervalValue !== event?.target?.value){
      setPrevIntervalValue(intervalValue);
      setIntervalValue(event.target.value);
      sessionStorage.setItem("intervalValue",event.target.value)
    }
  };

  const handleRadioBtnChange = (event) => {
    console.log("changed radio button to ", event?.target?.value);
    setRadioBtnValue(event?.target?.value);
    let e = { target: { value: "" } }
    e.target.value = intervalValue;
    handleIntervalChange(e);
  };

  const handleNormalBtnClick = () => {
    if (open) {
      // setDisplayedDate(convertDateRangeToFormattedString(oldDate));
      // var fromDateValue = new Date(oldDate)
      // fromDateValue.setUTCDate(fromDateValue.getDate())
      // fromDateValue.setUTCHours(0, 0, 0, 0)
      // setFromDate(fromDateValue);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDateFromChange = (value) => {
    setPrevOldDate(oldDate)
    setOldDate(new Date(value));
    sessionStorage.setItem("oldDate",new Date(value));
    console.log(
      "handleDateFromChange ",
      value,
      new Date(value),
    );
    setDisplayedDate(convertDateRangeToFormattedString(new Date(value)))
  };
  
  useEffect(() => {
    handleClickAway()
  }, [props.input])
  
  const handleClickAway = () => {
    console.log(
      "handleClickAway",
      convertDateRangeToFormattedString(oldDate)
    );
    setOpen(false);

    setDisplayedDate(convertDateRangeToFormattedString(oldDate));
    var fromDateValue = new Date(Date.UTC(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate()))
    if(props.fromDate?.toISOString().substring(0, 10) !== fromDateValue?.toISOString().substring(0, 10)) // so that same date is not updated again
      setFromDate(fromDateValue);
  };

  useEffect(()=>{
    setDisplayedDate(convertDateRangeToFormattedString(oldDate));
  },[oldDate])
  useEffect(()=>{
    sessionStorage.setItem("pastDate", oldDate);
    sessionStorage.setItem("radioButton",radioBtnValue);
  },[oldDate,radioBtnValue])
  const handleCancelBtn = () => {
    setOpen(false)
    setOldDate(prevOldDate);
    sessionStorage.getItem("oldDate",prevOldDate)
    setCurrentDate(prevCurrentDate)
    setNumberFieldvalue(prevNumberFieldValue);
    setIntervalValue(prevIntervalValue)
  }

  return (
    <MuiThemeProvider theme={UItheme}>
      <ClickAwayListener
      onClickAway={()=>{}}
      >
        <span className="dropdownLayout" >
          <Button
            variant="outlined"
            onClick={handleNormalBtnClick}
            style={{ color:"#8A8A8A",borderColor:"#D2D2D2", height: "36px", fontSize:"14px", textTransform: "none",
            borderRadius:'8px'
          }}
            size="medium"
          >
            <div style={{display:"flex",justifyContent:"space-between", minWidth:"180px"}}>
            {
              radioBtnValue==="fixed"?
                `${numberFieldvalue} ${intervalValue} Ago`
              :
                displayedDate
            }
            <KeyboardArrowDownRoundedIcon style={{fill: '#21DA8C'}}/>
            </div>
          </Button>

          {open ? (
            <Paper className="act_like_dropdown">
              <RadioGroup
                value={radioBtnValue}
                onChange={handleRadioBtnChange}
                style={{ marginTop: "18px" }}
              >
                <FormControlLabel
                  value="fixed"
                  control={<Radio />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <div style={{display:"flex",justifyContent:"space-between"}}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                          pattern: "[0-9]*",
                        }}
                        InputProps={{ inputProps: { min: 1 } }}
                        type="number"
                        onChange={handleNumberChange}
                        disabled={radioBtnValue !== "fixed"}
                        value={numberFieldvalue}
                        style={{ width: "40px", height:"32px" }}
                      />
                      <Select
                        value={intervalValue}
                        renderValue={(value) => <label>{value}</label>}
                        onChange={handleIntervalChange}
                        disabled={radioBtnValue !== "fixed"}
                        style={{width:"120px", height:"36px", paddingTop:"8px"}}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        // IconComponent={() => <KeyboardArrowDownRoundedIcon style={{fill: '#595959'}}/>}
                      >
                        {frequencyValues.map((data, index) => {
                          return (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <div style={{color:radioBtnValue==="fixed"?"rgba(0, 0, 0, 0.87)":"#00000061", paddingTop:"8px", width:"40px"}}>
                      Ago
                      </div>
                      </div>
                    </div>
                  }
                />

                <FormControlLabel
                  value="custom"
                  style={{
                    alignItems: "flex-start",
                    paddingTop: "20px",
                  }}
                  control={<Radio />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography style={{color:radioBtnValue==="fixed"?"#00000061":"rgba(0, 0, 0, 0.87)"}}>
                        Custom Date
                      </Typography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={radioBtnValue === "fixed"}
                          maxDate={fromDateMax}
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="none"
                          value={oldDate}
                          onChange={handleDateFromChange}
                          autoOk={true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  }
                />
              </RadioGroup>
              <div style={{display: "flex", marginTop: "15px", alignContent: "center",  justifyContent: "flex-end"}}>
                <Button variant="outlined" color="primary" onClick={handleCancelBtn} style={{marginRight: "24px"}}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickAway}>
                  Apply
                </Button>
              </div>
            </Paper>
          ) : null}
        </span>
      </ClickAwayListener>
    </MuiThemeProvider>
  );
}

export default TimeSpanComponentForFunnel;
